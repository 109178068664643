import React, { useEffect, useState } from 'react';
import './home.scss';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useHistory } from 'react-router';

export default function Home() {
    const [intro, setIntro] = useState<boolean>(false);
    const [category, setCategory] = useState<string | null>(null);
    const [subCategory, setSubCategory] = useState<string | null>(null);

    const history = useHistory();

    useEffect(() => {
        setCategory(sessionStorage.getItem('category'));
        setSubCategory(sessionStorage.getItem('subCategory'));

    }, []);

    useEffect(() => {
       if (category === 'kid') {
        sessionStorage.setItem('category', category);
        sessionStorage.setItem('subCategory', 'boy');
        history.push('/select-design');
        }
        
        if (subCategory && category) {
            sessionStorage.setItem('category', category);
            sessionStorage.setItem('subCategory', subCategory);
            history.push('/select-design');
        }
    }, [category, subCategory]);



    return (
        <React.Fragment>
            <Container fluid >
                <Row className="home">
                    <Col lg={6} className="bg-primary ">
                        <div className="card pageCenter">
                            <img src="/assets/images/logo.png" alt="logo" width="100" />
                            <div className="body">
                                {!intro ?
                                    <React.Fragment>
                                        <h1 className="brand">Eli's Dyes</h1>
                                        <h2>No Shirt Created Equal</h2>
                                        <h4>The most interactive designer</h4>
                                    </React.Fragment>
                                    : null}
                                {intro && !category && !subCategory ?
                                    <React.Fragment>
                                        <div className="option-title">
                                            <h4>Select Your</h4>
                                            <h2>Match</h2>
                                        </div>
                                    </React.Fragment>
                                    : null}
                                {intro && category && !subCategory ?
                                     <React.Fragment>
                                        <div className="option-title">
                                            <h4>Select Your</h4>
                                            <h2>Gender</h2>
                                        </div>
                                    </React.Fragment>
                                    : null}
                            </div>
                            <div className="footer">
                                {!intro ?
                                    <Button variant="outline-dark" size="lg" onClick={() => { setIntro(true) }}>Start Designing</Button>
                                    : null}
                                {intro && !category && !subCategory ?
                                    <Button variant="dark" size="lg" onClick={() => { setIntro(false) }}>Back</Button>
                                    : null}
                                {intro && category && !subCategory ?
                                    <Button variant="dark" size="lg" onClick={() => { setCategory(null) }}>Back</Button>
                                    : null}

                            </div>
                        </div>
                    </Col>
                    <Col lg={6} className="bg-secondary ">

                        {!intro ?
                            <img className="pageCenter " src="/assets/images/t-shirt-1.png" alt="#" />
                            : null}
                        {intro && !category && !subCategory ?
                            <div className="pageCenter d-flex justify-content-center">
                                <div className="option">
                                    <img src="/assets/images/man.png" alt="#" />
                                    <Button onClick={() => { setCategory('adult') }}>Adult</Button>
                                </div>

                                <div className="option">
                                    <img src="/assets/images/kid-1.png" alt="#" />
                                    <Button onClick={() => { setCategory('kid') }}>Kid</Button>
                                </div>
                            </div>
                            : null}
                        {intro && category && !subCategory ?
                            <div className="pageCenter d-flex justify-content-center">
                                {category === 'adult' ?
                                    <React.Fragment>
                                        <div className="option">
                                            <img src="/assets/images/adult-1.png" alt="#" />
                                            <Button onClick={() => { setSubCategory('men') }}>Men</Button>
                                        </div>

                                        <div className="option">
                                            <img src="/assets/images/adult-2.png" alt="#" />
                                            <Button onClick={() => { setSubCategory('women') }}>Women</Button>
                                        </div>
                                    </React.Fragment>
                                    : null}
                                {category === 'kid' ?
                                    <React.Fragment>
                                        <div className="option">
                                            <img src="/assets/images/kid-1.png" alt="#" />
                                            <Button onClick={() => { setSubCategory('boy') }}>Boy</Button>
                                        </div>

                                        <div className="option">
                                            <img src="/assets/images/kid-2.png" alt="#" />
                                            <Button onClick={() => { setSubCategory('girl') }}>Girl</Button>
                                        </div>
                                    </React.Fragment>
                                    : null}
                            </div>
                            : null}


                    </Col>
                </Row>
            </Container>

        </React.Fragment>
    )
}